import axios from "axios";
import Vue from "vue";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  store: null,
  programGroupKey: null,
  clientKey: null,
  baseUrl: null,
  httpRedirects: null,

  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
    if (opts.programGroupKey) {
      this.programGroupKey = opts.programGroupKey;
    }
    if (opts.clientKey) {
      this.clientKey = opts.clientKey;
    }
    if (opts.baseUrl) {
      this.baseUrl = opts.baseUrl;
    } else {
      this.baseUrl = window.location.host;
    }
    if (opts.httpRedirects) {
      this.httpRedirects = opts.httpRedirects;
    }

    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = this.baseUrl;
    /*
    Vue.axios.interceptors.request.use(
      request => {
        return request;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // Response interceptor
    Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        return Promise.reject(error);
      }
    );
    */
  },

  /**
   *
   * @param {*} property
   * @param {*} object
   */
  getRelatedObject(property, object) {
    return this.get(this.getLinkUrl(property, object));
  },

  /**
   * Convenience method to retrieve the self link href within an object
   * @param {} type
   * @param {*} obj
   */
  getSelfUrl(type, obj) {
    return this.baseUrl + "/api/" + type + "/" + obj.id;
  },

  /**
   * Convenience method to retrieve the link href url within an object
   * @param {*} type
   * @param {*} obj
   */
  getLinkUrl(type, obj) {
    let href = obj && obj._links && obj._links[type] && obj._links[type].href ? obj._links[type].href : null;
    if (href != null && !href.startsWith(this.baseUrl)) {
      let index = href.indexOf("/api");
      if (index > -1) {
        href = this.baseUrl + href.substring(index);
      }
    }
    return href;
  },

  createError(message) {
    let apiError = new ApiError();
    apiError.message = message;
    return apiError;
  },

  /**
   * Utility function used by forms to retrieve an error array that can be used to format server-side validation errors
   * @param {*} error
   */
  getErrorsFromResponse(error) {
    let status = error && error.response && error.response.status ? error.response.status : null;
    let apiErrors = new ApiError();
    if (typeof error == "string") {
      apiErrors.message = error;
    } else if (error && error.response && error.response.data) {
      let errorMessage = error.response.data.errorMessage;
      apiErrors.httpStatusCode = status;
      if (errorMessage && errorMessage.startsWith("Validation failed") && status == 400) {
        apiErrors.message = "Validation errors occurred while saving.  Please resolve.";
        error.response.data.errors.forEach(e => {
          apiErrors.addValidationError(e.property, e.message);
        });
      } else if (errorMessage === "Integrity Violation" && status == 409) {
        apiErrors.message = "There is a constraint or dependency that prevented your request";
        apiErrors.addValidationError("Constraint", error.response.data.exceptionMessage);
      } else if (status == 404) {
        apiErrors.message = "Resource not found";
      } else if (status == 409 || status == 413) {
        apiErrors.message = errorMessage;
      } else if (errorMessage) {
        apiErrors.message = errorMessage;
      } else {
        apiErrors.message = "An unkown error occurred. Please try again";
      }
    }

    if (
      error &&
      error.response &&
      error.response.status &&
      this.httpRedirects &&
      this.httpRedirects[error.response.status]
    ) {
      // this.$router.push(this.httpRedirects[error.response.status]); this doesn't work.
    }
    if (!apiErrors.message && error && error.message) {
      apiErrors.message = error.message;
    }

    return apiErrors;
  },

  isUnauthorized(errorResponse) {
    return (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.status == 401
    );
  },

  isForbidden(errorResponse) {
    return (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.status == 403
    );
  },

  isCredentialsExpired(errorResponse) {
    return (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.status == 412
    );
  },

  getCredentialsExpirationKey(errorResponse) {
    if (this.isCredentialsExpired(errorResponse)) {
      return errorResponse.response.data.errorMessage;
    } else {
      return "";
    }
  },

  isPayloadTooLarge(errorResponse) {
    return (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.status == 413
    );
  },

  isLocked(errorResponse) {
    return (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.status == 423
    );
  },

  getLockedMessage(errorResponse) {
    if (this.isLocked(errorResponse)) {
      return errorResponse.response.data.errorMessage;
    } else {
      return "";
    }
  },

  async postAndReturnTypedArray(resource) {
    const response = await Vue.axios.post(resource, {
      responseType: "arraybuffer"
    });
    const typedArray = new Uint8Array(response.data);
    return typedArray;
  },

  async getAndReturnTypedArray(resource) {
    const response = await Vue.axios.get(resource, {
      responseType: "arraybuffer"
    });
    const typedArray = new Uint8Array(response.data);
    return typedArray;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeaders() {
    if (JwtService.getToken()) {
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    } else {
      delete Vue.axios.defaults.headers.common["Authorization"];
    }

    // Always accept 'application/json'
    Vue.axios.defaults.headers.common["Accept"] = "application/json";

    // Program Group Key needs to be passed on every request
    let programGroupKey = this.programGroupKey;
    Vue.axios.defaults.headers.common["ProgramGroupKey"] = programGroupKey;

    // Client Key needs to be passed on every request
    let clientKey = this.clientKey;
    Vue.axios.defaults.headers.common["ClientKey"] = clientKey;

    // If a client is selected, then pass it on the request
    const client = this.store.getters.selectedClient;
    if (client && client.id) {
      Vue.axios.defaults.headers.common["SelectedClient"] = client.id;
    } else {
      delete Vue.axios.defaults.headers.common["SelectedClient"];
    }

    // If a program is selected, then pass it on the request
    const program = this.store.getters.selectedProgram;
    if (program && program.id) {
      Vue.axios.defaults.headers.common["SelectedProgram"] = program.id;
    } else {
      delete Vue.axios.defaults.headers.common["SelectedProgram"];
    }

    // If a participant is selected, then pass it on the request
    const participant = this.store.getters.selectedParticipant;
    if (participant && participant.id) {
      Vue.axios.defaults.headers.common["SelectedParticipant"] = participant.id;
    } else {
      delete Vue.axios.defaults.headers.common["SelectedParticipant"];
    }

    // If a participant is selected, then pass it on the request
    const iParticipant = this.store.getters.impersonationParticipant;
    if (iParticipant && iParticipant.id) {
      Vue.axios.defaults.headers.common["ImpersonationParticipant"] = iParticipant.id;
    } else {
      delete Vue.axios.defaults.headers.common["ImpersonationParticipant"];
    }

    // If a storeProgram is selected, then pass it on the request
    const storeProgram = this.store.getters.selectedStoreProgram;
    if (storeProgram && storeProgram.id) {
      Vue.axios.defaults.headers.common["SelectedStoreProgram"] = storeProgram.id;
    } else {
      delete Vue.axios.defaults.headers.common["SelectedStoreProgram"];
    }

    // If a locale is selected, then pass it on the request
    const locale = this.store.getters.selectedLocale;
    if (locale && locale.id) {
      Vue.axios.defaults.headers.common["SelectedLocale"] = locale.id;
    } else {
      delete Vue.axios.defaults.headers.common["SelectedLocale"];
    }
  },

  query(resource, params) {
    this.setHeaders();
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @returns {*}
   */
  get(resource) {
    this.setHeaders();
    return Vue.axios.get(resource);
  },

  getWithConfig(resource, config) {
    this.setHeaders();
    return Vue.axios.get(resource, config);
  },

  getWithCaptcha(resource, action) {
    this.setHeaders();
    return Vue.prototype.$recaptchaLoaded().then(() => {
      return Vue.prototype.$recaptcha(action ? action : "generic").then(token => {
        let finalUrl = resource.includes("?")
          ? resource + "&g-recaptcha-response=" + token
          : resource + "?g-recaptcha-response=" + token;
        return Vue.axios.get(finalUrl);
      });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config) {
    this.setHeaders();
    return Vue.axios.post(resource, params, config);
  },

  postWithCaptcha(resource, params, config, action) {
    this.setHeaders();

    return Vue.prototype.$recaptchaLoaded().then(() => {
      return Vue.prototype.$recaptcha(action ? action : "generic").then(token => {
        return Vue.axios.post(
          resource + (resource.includes("?") ? "&" : "?") + "g-recaptcha-response=" + token,
          params,
          config
        );
      });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, params) {
    this.setHeaders();
    return Vue.axios.put(resource, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeaders();
    return Vue.axios.put(resource, params);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    this.setHeaders();
    return Vue.axios.patch(resource, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeaders();
    return Vue.axios.delete(resource);
  }
};
export default ApiService;

class ApiError {
  httpStatusCode = 0;
  message = "";
  validationErrors = {};

  constructor(httpStatusCode = 0, message = "", validationErrors = {}) {
    this.httpStatusCode = httpStatusCode;
    this.message = message;
    this.validationErrors = validationErrors;
  }

  getValidationError(key) {
    return this.validationErrors[key];
  }
  clearValidationError(key) {
    this.validationErrors[key] = undefined;
  }
  addValidationError(key, value) {
    if (this.validationErrors[key]) {
      this.validationErrors[key] += `\n${value}`;
    } else {
      this.validationErrors[key] = value;
    }
  }
  isBadRequest() {
    return this.httpStatusCode == 400;
  }
  isUnauthorized() {
    return this.httpStatusCode == 401;
  }
  isForbidden() {
    return this.httpStatusCode == 403;
  }
  isNotFound() {
    return this.httpStatusCode == 404;
  }
  isNotAllowed() {
    return this.httpStatusCode == 405;
  }
  isRequestTimeOut() {
    return this.httpStatusCode == 408;
  }
  isCredentialsExpired() {
    return this.httpStatusCode == 412;
  }
  isPayloadTooLarge() {
    return this.httpStatusCode == 413;
  }
  isLocked() {
    return this.httpStatusCode == 423;
  }
}
