import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/eur/rebates"
  },
  {
    path: "/eur",
    redirect: "/eur/rebates"
  },
  {
    path: "/eur/rebates",
    component: () => import("./views/LayoutPublicEur.vue"),
    props: { showMenu: true, footerDark: false },
    children: [
      {
        path: "/eur/rebates",
        name: "eurList",
        component: () => import("./views/eur/EurList.vue")
      },
      {
        path: "/eur/eurFaq",
        name: "eurFaq",
        component: () => import("./views/eur/EurFaq.vue")
      },
      {
        path: "/eur/eurClaim/:id",
        name: "eurClaim",
        component: () => import("./views/eur/EurClaim.vue")
      },
      {
        path: "/eur/eurTrack",
        name: "eurTrack",
        component: () => import("./views/eur/EurTrack.vue")
      },
      {
        path: "/eur/service-and-support",
        name: "eurListServiceAndSupport",
        component: () => import("./views/eur/EurListServiceAndSupport.vue")
      },
      {
        path: "/eur/faith",
        name: "eurListFaith",
        component: () => import("./views/eur/EurListFaith.vue")
      }
    ]
  },
  {
    path: "*",
    redirect: "/eur/rebates"
  },
  {
    path: "/eur/BRAVIAwarranty",
    redirect: { name: "eurBraviaWarranty" }
  },
  {
    path: "/",
    component: () => import("./views/LayoutPublicEur.vue"),
    props: { showMenu: false, footerDark: true },
    children: [
      {
        path: "/eur/bravia-warranty",
        name: "eurBraviaWarranty",
        component: () => import("./views/eur/EurBraviaWarranty.vue")
      },
      {
        path: "/eur/whiteglove",
        name: "eurWhiteglove",
        component: () => import("./views/eur/EurWhiteglove.vue")
      },
      {
        path: "/eur/extended-warranty",
        name: "eurExtendedWarranty",
        component: () => import("./views/eur/EurExtendedWarranty.vue")
      },
      {
        path: "/eur/product-registration",
        name: "eurProductRegistration",
        component: () => import("./views/eur/EurProductRegistration.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutComingSoon.vue"),
    children: [
      {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("./views/ComingSoonEur.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.onError(error => {
  if (error.name === "ChunkLoadError" || /Loading chunk \d+ failed/g.test(error.message)) {
    window.location.reload();
  }
});

export default router;
