import store from "../store";
import formatDateClient from "./format-date-client";

export default {
  name: "payoutVerbiage",
  fn: (promotionProductPayout, promotionCurrency) => {
    let verbiage = "";

    // Dates
    if (promotionProductPayout.effectiveDate && promotionProductPayout.expirationDate) {
      verbiage +=
        "Between " +
        formatDateClient.fn(promotionProductPayout.effectiveDate, "MM/DD/YYYY", store.getters.selectedClient) +
        " and " +
        formatDateClient.fn(promotionProductPayout.expirationDate, "MM/DD/YYYY", store.getters.selectedClient) +
        " ";
    } else if (promotionProductPayout.effectiveDate) {
      verbiage +=
        "Starting on " +
        formatDateClient.fn(promotionProductPayout.effectiveDate, "MM/DD/YYYY", store.getters.selectedClient) +
        " ";
    } else if (promotionProductPayout.expirationDate) {
      verbiage +=
        "Ending on " +
        formatDateClient.fn(promotionProductPayout.expirationDate, "MM/DD/YYYY", store.getters.selectedClient) +
        " ";
    }

    let productKey = undefined;
    if (promotionProductPayout.payoutStoreCatalogProduct) {
      productKey = promotionProductPayout.payoutStoreCatalogProduct.storeProduct.productNumber;
    }

    // payout
    if (promotionProductPayout.payoutType) {
      switch (promotionProductPayout.payoutType.name) {
        case "PERCENT_CLAIM_PRODUCT_PRICE_AMOUNT_CLAIMED":
          verbiage +=
            "Payout " +
            (promotionProductPayout.payoutAmount ? promotionProductPayout.payoutAmount : "...") +
            "% of product price amount";
          break;
        case "PERCENT_CLAIM_AMOUNT_CLAIMED":
          verbiage +=
            "Payout " +
            (promotionProductPayout.payoutAmount ? promotionProductPayout.payoutAmount : "...") +
            "% of claim invoice amount";
          break;
        case "PERCENT_PRODUCT_AMOUNT_CLAIMED":
          verbiage +=
            "Payout " +
            (promotionProductPayout.payoutAmount ? promotionProductPayout.payoutAmount : "...") +
            "% of product amount";
          break;
        case "FLAT_AMOUNT_PER_UNIT_CLAIMED":
          verbiage +=
            "Payout " +
            (promotionProductPayout.payoutAmount ? promotionProductPayout.payoutAmount : "...") +
            (promotionCurrency ? " " + promotionCurrency : "") +
            " for each product";
          break;
        case "PRODUCT":
          verbiage +=
            "Payout " +
            (promotionProductPayout.payoutProductQuantity == 1
              ? promotionProductPayout.payoutProductQuantity + " product "
              : promotionProductPayout.payoutProductQuantity + " products ") +
            (productKey ? "(" + productKey + ")" : "(...)");

          switch (promotionProductPayout.payoutProductMethodType.name) {
            case "EACH":
              verbiage += " for each unit";
              break;
            case "EVERY_THRESHOLD":
              verbiage +=
                " for every " +
                promotionProductPayout.payoutProductThreshold +
                " " +
                (promotionProductPayout.payoutProductThreshold == 1 ? "unit" : "units") +
                " claimed";
              break;
            case "FIRST_THRESHOLD":
              verbiage +=
                " after the first " +
                promotionProductPayout.payoutProductThreshold +
                " " +
                (promotionProductPayout.payoutProductThreshold == 1 ? "unit" : "units") +
                " claimed";
              break;
            default:
          }
          break;
        case "COUPON_CODE":
          verbiage +=
            "Payout " +
            (promotionProductPayout.payoutProductQuantity == 1
              ? promotionProductPayout.payoutProductQuantity + " coupon "
              : promotionProductPayout.payoutProductQuantity + " coupons ");

          switch (promotionProductPayout.payoutProductMethodType.name) {
            case "EACH":
              verbiage += " for each unit claimed";
              break;
            case "EVERY_THRESHOLD":
              verbiage +=
                " for every " +
                promotionProductPayout.payoutProductThreshold +
                " " +
                (promotionProductPayout.payoutProductThreshold == 1 ? "unit" : "units") +
                " claimed";
              break;
            case "FIRST_THRESHOLD":
              verbiage +=
                " after the first " +
                promotionProductPayout.payoutProductThreshold +
                " " +
                (promotionProductPayout.payoutProductThreshold == 1 ? "unit" : "units") +
                " claimed";
              break;
            default:
          }
          break;
        default:
          verbiage += "Payout " + promotionProductPayout.payoutType.description;
      }
    }
    return verbiage;
  }
};
