import Vue from "vue";
import VueRouter from "vue-router";
import routesAdmin from "./routesAdmin";
import routesCommonAuthenticated from "./routesCommonAuthenticated";
import routesCommonPublic from "./routesCommonPublic";
import routesReports from "./routesReports";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("./views/eve/LayoutAuthenticated.vue"),
    meta: {
      requiresAuthentication: true
    },
    children: [
      // Common authenticated routes
      ...routesCommonAuthenticated.routes(),

      // Admin routes
      ...routesAdmin.routes(),

      // Report routes
      ...routesReports.routes(),

      // Dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("./views/Dashboard.vue")
      },
      {
        path: "/surveys/",
        name: "cprSurveys",
        component: () => import("./views/common/survey/Surveys.vue")
      },
      {
        path: "/surveys/:id",
        name: "cprSurvey",
        component: () => import("./views/common/survey/Survey.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/eve/LayoutPublic.vue"),
    children: [
      // Common public routes
      ...routesCommonPublic.routes(),

      {
        path: "/login",
        name: "login",
        component: () => import("./views/eve/Login.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.onError(error => {
  if (error.name === "ChunkLoadError" || /Loading chunk \d+ failed/g.test(error.message)) {
    window.location.reload();
  }
});

export default router;
