import ApiService from "./api.service";
import moment from "moment-timezone";
import { UPDATE_IS_BUSY } from "../store/claim.module";

const ClaimService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  submitClaim(isPublic = false) {
    this.isBusy = true;
    let store = this.store;
    store.dispatch(UPDATE_IS_BUSY, true);
    const postForm = this.preparePostFormData(isPublic);
    postForm.calculationOnly = false;
    let url, request;
    if (store.getters.claimId == undefined) {
      url = isPublic ? "/api/claims/submit/public" : "/api/claims/submit";
      request = isPublic
        ? ApiService.postWithCaptcha(url, postForm, null, "publicClaim")
        : ApiService.post(url, postForm);
    } else {
      postForm.submitAfterUpdate = true;
      url = `/api/claims/${store.getters.claimId}/update`;
      request = ApiService.post(url, postForm);
    }

    return request
      .then(({ data }) => {
        this.claimNumber = data.claimKey;
        this.isBusy = false;
        this.claimSubmitted = true;
        return data;
      })
      .catch(error => {
        this.errors = ApiService.getErrorsFromResponse(error);
        this.isBusy = false;
        return Promise.reject(this.errors);
      })
      .finally(() => {
        store.dispatch(UPDATE_IS_BUSY, false);
      });
  },

  preparePostFormData(isPublic = false, availableAwardVehicles = [], publicParticipantTypeKey = null) {
    let store = this.store;
    let postForm = {};

    postForm = this.getBasePostForm(isPublic);
    postForm.preferredAwardVehicle = store.getters.selectedAwardVehicle;
    postForm.browserSource = window.location.href;
    postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();

    if (!postForm.preferredAwardVehicle && availableAwardVehicles?.length > 0) {
      postForm.preferredAwardVehicle = availableAwardVehicles[0];
    }

    if (store.getters.mode === "ZERO_SALES") {
      postForm.zeroSales = true;
      postForm.zeroSalesDate = moment
        .tz(store.getters.selectedZeroSalesPeriod, store.getters.selectedClient.timezone)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      postForm.uploads = [];
      postForm.claimProducts = [];
      postForm.claimFieldValues = [];
    } else {
      postForm.zeroSales = false;
      postForm.uploads = store.getters.documentation.existing?.length > 0 ? store.getters.documentation.existing : [];

      if (store.getters.claimUploads?.existing?.length) {
        postForm.uploads = postForm.uploads.concat(store.getters.claimUploads.existing);
      }

      postForm.claimProducts = this.getClaimProducts(
        store.getters.promotion,
        store.getters.claimProducts,
        store.getters.tradeInProducts
      );
      postForm.claimFieldValues = this.getClaimFieldValues(store.getters.claimFields, store.getters.claimFieldValues);
      postForm.productRegistrationItems = this.getProductsToRegister(
        store.getters.promotion,
        store.getters.productsToRegister
      );
    }

    if (isPublic) {
      postForm.participantStatus = { name: "ENR" };
      postForm.reuseParticipant = true;
      postForm.optIn = store.getters.optIn;
      postForm.emailTemplateParticipantForm = { emailTemplateKey: "END_USER_CLAIM_SUBMISSION" };
      if (publicParticipantTypeKey) {
        postForm.participantTypeKey = publicParticipantTypeKey;
      } else {
        postForm.participantTypeKey = "950";
      }
    }

    return postForm;
  },

  getReferrerFromQueryString() {
    var queryString = window.location.search.substring(1);
    var params = new URLSearchParams(queryString);

    if (params.has("referrer")) {
      return params.get("referrer");
    } else {
      return null;
    }
  },

  calculateTotalClaimAmount(availableAwardVehicles, isPublic = false, publicParticipantTypeKey = null) {
    if (!availableAwardVehicles || availableAwardVehicles.length === 0) {
      return Promise.reject(new Error("No available award vehicles found for the claim"));
    }
    let postForm = this.preparePostFormData(isPublic, availableAwardVehicles, publicParticipantTypeKey);
    postForm.calculationOnly = true;

    const url = isPublic ? "/api/claims/calculateTotalClaimAmount/public" : "/api/claims/calculateTotalClaimAmount";
    const request = isPublic
      ? ApiService.postWithCaptcha(url, postForm, null, "calculateTotalClaimAmountPublic")
      : ApiService.post(url, postForm);

    return request.then(({ data }) => data.totalClaimAmount);
  },

  getBasePostForm(isPublic = false) {
    let store = this.store;
    let basePostForm = {};
    basePostForm.promotionKey = store.getters.promotion.promotionKey;
    basePostForm.emailTemplateParticipantForm = {
      emailTemplateKey: "CLAIM_SUBMISSION"
    };
    basePostForm.saveEndUserForLater = store.getters.selectedEndUser ? false : store.getters.saveEndUserForLater;
    if (store.getters.participantSelectedToClaimOnBehalf && store.getters.participantSelectedToClaimOnBehalf.id) {
      basePostForm.participant = { id: store.getters.participantSelectedToClaimOnBehalf.id };
    } else if (!isPublic) {
      basePostForm.participant = { id: store.getters.selectedParticipant.id };
    }
    return basePostForm;
  },

  getClaimFieldValues(claimFields, claimFieldValues) {
    const claimFieldsClone = claimFields.slice();
    let cfValues = [];
    claimFieldsClone.map(claimField => {
      if (
        claimFieldValues[claimField.id] &&
        claimFieldValues[claimField.id].existing &&
        claimFieldValues[claimField.id].existing.length > 0
      ) {
        claimFieldValues[claimField.id] = claimFieldValues[claimField.id].existing[0];
      }
      let cfValue = {
        claimField: { id: claimField.id, claimType: claimField.claimType },
        value:
          claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
            ? claimFieldValues[claimField.id].name.trim()
            : claimFieldValues[claimField.id] && claimFieldValues[claimField.id].id
            ? claimFieldValues[claimField.id].id
            : claimFieldValues[claimField.id] && typeof claimFieldValues[claimField.id] === "string"
            ? claimFieldValues[claimField.id].trim()
            : claimFieldValues[claimField.id]
      };
      cfValues.push(cfValue);
    });

    return cfValues;
  },

  getClaimProducts(promotion, claimProducts, tradeInProducts) {
    let claimProductsToReturn = [];
    [...(claimProducts ?? []), ...(tradeInProducts ?? [])].forEach(claimProduct => {
      let cp = Object.assign({}, claimProduct);
      cp.priceAmountCurrency = { name: promotion.country.currencyName };
      cp.promotionProduct = { id: claimProduct.promotionProduct.id };
      claimProductsToReturn.push(cp);
    });

    return claimProductsToReturn;
  },

  getProductsToRegister(promotion, productsToRegister) {
    let productsToRegisterToReturn = [];
    productsToRegister.forEach(productToRegister => {
      let productToRegisterCopy = Object.assign({}, productToRegister);
      productToRegisterCopy.priceAmountCurrency = { name: promotion.country.currencyName };
      productToRegisterCopy.product = { id: productToRegister.promotionProduct.product.id };
      productsToRegisterToReturn.push(productToRegisterCopy);
    });

    return productsToRegisterToReturn;
  },

  verifyPromotionInvitationKey(key, isPublic) {
    if (key && key.length > 0) {
      this.isBusy = true;
      if (isPublic) {
        return ApiService.get("/api/promotionInvitations/verify/" + key + "/public");
      } else {
        return ApiService.get("/api/promotionInvitations/verify/" + key);
      }
    } else {
      return Promise.reject("Empty key");
    }
  }
};

export default ClaimService;
