import Vue from "vue";
import VueRouter from "vue-router";
import routesAdmin from "./routesAdmin";
import routesCommonAuthenticated from "./routesCommonAuthenticated";
import routesCommonPublic from "./routesCommonPublic";
import routesReports from "./routesReports";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("./views/LayoutAuthenticated.vue"),
    meta: {
      requiresAuthentication: true
    },
    children: [
      // Common authenticated routes
      ...routesCommonAuthenticated.routes(),

      // Admin routes
      ...routesAdmin.routes(),

      // Report routes
      ...routesReports.routes(),

      // Dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("./views/Dashboard.vue")
      },

      // CPR (CHANNEL PARTNER REBATE) VIEWS
      {
        path: "/cpr/claims",
        name: "cprClaims",
        component: () => import("./views/cpr/CprClaims.vue"),
        meta: {
          participantTypes: [
            "100",
            "500",
            "510",
            "520",
            "530",
            "600",
            "610",
            "620",
            "700",
            "710",
            "720",
            "730",
            "800",
            "810",
            "820",
            "830",
            "900",
            "910",
            "930",
            "1030"
          ]
        }
      },
      {
        path: "/cpr/claims/:id",
        name: "cprClaim",
        component: () => import("./views/cpr/CprClaim.vue")
      },
      {
        path: "/cpr/claimsOcr/:id",
        name: "cprClaimOcr",
        component: () => import("./views/cpr/CprClaimOcr.vue")
      },
      {
        path: "/cpr/claimsFileFeed",
        name: "cprClaimsFileFeed",
        component: () => import("./views/cpr/CprClaimsFileFeed.vue"),
        meta: {
          participantTypes: [
            "100",
            "500",
            "510",
            "520",
            "530",
            "600",
            "610",
            "620",
            "700",
            "710",
            "720",
            "730",
            "800",
            "810",
            "820",
            "830",
            "900",
            "910",
            "930"
          ]
        }
      },
      {
        path: "/cpr/accounts",
        name: "cprMyAccounts",
        component: () => import("./views/cpr/accounts/MyAccounts.vue"),
        meta: {
          participantTypes: ["400"]
        }
      },
      {
        path: "/cpr/accounts/:id",
        name: "cprMyAccountsWithId",
        component: () => import("./views/cpr/accounts/MyAccounts.vue"),
        meta: {
          participantTypes: ["300"]
        }
      },
      {
        path: "/cpr/accountManagers",
        name: "cprMyAccountManagers",
        component: () => import("./views/cpr/accounts/MyAccountManagers.vue"),
        meta: {
          participantTypes: ["300"]
        }
      },
      {
        path: "/cpr/myClaims",
        name: "cprMyClaims",
        component: () => import("./views/cpr/MyClaims.vue")
      },
      {
        path: "/cpr/company/myCompanyClaims",
        name: "cprMyCompanyClaims",
        component: () => import("./views/cpr/company/MyCompanyClaims.vue")
      },
      {
        path: "/cpr/company/myCompanyClaims/:id",
        name: "cprMyCompanyClaimsWithId",
        component: () => import("./views/cpr/company/MyCompanyClaims.vue")
      },
      {
        path: "/cpr/myEarnings",
        name: "cprMyEarnings",
        component: () => import("./views/cpr/MyEarnings.vue")
      },
      {
        path: "/ccpr/myCompanyEarnings",
        name: "ccprMyCompanyEarnings",
        component: () => import("./views/ccpr/MyCompanyEarnings.vue"),
        meta: {
          participantTypes: ["100", "500", "600", "700", "800"]
        }
      },
      {
        path: "/ccpr/select-award/:id",
        name: "SelectAward",
        component: () => import("./views/ccpr/rsa-incentive/SelectAward.vue")
      },
      { path: "/surveys/", name: "cprSurveys", component: () => import("./views/common/survey/Surveys.vue") },
      { path: "/surveys/:id", name: "cprSurvey", component: () => import("./views/common/survey/Survey.vue") }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutPublicThinCpr.vue"),
    children: [
      // Common public routes
      ...routesCommonPublic.routes(),
      {
        path: "/login",
        name: "login",
        component: () => import("./views/ccpr/CcprLogin.vue")
      },
      {
        path: "/views/preEnroll",
        name: "pre-enroll",
        component: () => import("./views/PreEnroll.vue")
      },
      {
        path: "/registerRSA",
        name: "CcprRegister",
        component: () => import("./views/ccpr/CcprRegister.vue")
      },
      {
        path: "/ccpr/sony-bucks",
        name: "SonyBucksRegister",
        component: () => import("./views/ccpr/sony-bucks/SonyBucksRegister.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutComingSoon.vue"),
    children: [
      {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("./views/ComingSoon.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.onError(error => {
  if (error.name === "ChunkLoadError" || /Loading chunk \d+ failed/g.test(error.message)) {
    window.location.reload();
  }
});

export default router;
