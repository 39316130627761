import ApiService from "./api.service";
import AuthService from "./auth.service";

const AwardVehicleService = {
  store: null,
  hqEmail: null,
  hqPhone: null,

  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   * Depending on what is passed into this method, we'll attempt to find available award vehicles
   *
   * @param {*} participant Participant
   * @param {*} promotion Promotion
   * @returns available award vehicles
   */
  async getAvailableAwardVehicles(participant = null, organization = null, promotion = null, isPublic = false) {
    try {
      const url = this.buildAwardVehiclesUrl(participant, organization, promotion, isPublic);
      if (!url) {
        return Promise.reject("No participant or organization or promotion provided");
      }
      const { data } = await ApiService.get(url);
      return data;
    } catch (error) {
      console.error("Error fetching available award vehicles:", error);
      throw error;
    }
  },

  buildAwardVehiclesUrl(participant, organization, promotion, isPublic) {
    if (promotion && isPublic) {
      return `/api/awardVehicles/available/byPromotion/${promotion.id}/public`;
    }

    if (promotion && participant) {
      return this.getUrlForPromotionAndParticipant(promotion, participant);
    }

    if (promotion && organization) {
      return this.getUrlForPromotionAndOrganization(promotion, organization);
    }

    if (participant) {
      return this.getUrlForParticipant(participant);
    }

    if (organization) {
      return this.getUrlForOrganization(organization);
    }

    if (promotion) {
      return `/api/awardVehicles/available/byPromotion/${promotion.id}/public`;
    }

    return null; // No promotion or participant provided
  },

  getUrlForPromotionAndParticipant(promotion, participant) {
    const isSelectedParticipant = participant.id === this.store.getters.selectedParticipant.id;
    return isSelectedParticipant
      ? `/api/awardVehicles/available/byPromotion/${promotion.id}`
      : `/api/awardVehicles/available/byPromotion/${promotion.id}/byParticipant/${participant.id}`;
  },

  getUrlForPromotionAndOrganization(promotion, organization) {
    const selectedParticipant = this.store.getters.selectedParticipant.id;
    const isSelectedOrganization =
      selectedParticipant.organization && selectedParticipant.organization.id == organization.id;
    return isSelectedOrganization
      ? `/api/awardVehicles/available/byPromotion/${promotion.id}`
      : `/api/awardVehicles/available/byPromotion/${promotion.id}/byOrganization/${organization.id}`;
  },

  getUrlForParticipant(participant) {
    const isSelectedParticipant = participant.id === this.store.getters.selectedParticipant.id;
    return isSelectedParticipant
      ? "/api/awardVehicles/available/byParticipant"
      : `/api/awardVehicles/available/byParticipant/${participant.id}`;
  },

  getUrlForOrganization(organization) {
    return `/api/awardVehicles/available/byOrganization/${organization.id}`;
  },

  /**
   * Get preferred award vehicle, unless it is forced
   *
   * @param {} participant
   * @returns preferred award vehicle
   */
  async getPreferredAwardVehicle(participant) {
    if (participant.forcedAwardVehicle) {
      return participant.forcedAwardVehicle;
    } else if (participant.organization && participant.organization.forcedAwardVehicle) {
      return participant.organization.forcedAwardVehicle;
    } else {
      return ApiService.get("/api/participants/" + participant.id + "/preferredAwardVehicle")
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          console.log("Preferred award vehicle not found");
        });
    }
  },

  async setSelectedAwardVehicleForCurrentParticipant(awardVehicle) {
    ApiService.patch("/api/participants/" + this.store.getters.selectedParticipant.id, {
      preferredAwardVehicle: ApiService.getSelfUrl("awardVehicles", awardVehicle)
    }).then(() => {
      this.store.getters.selectedParticipant.preferredAwardVehicle = awardVehicle;
      AuthService.storeSelectParticipant(this.store.getters.selectedParticipant);
    });
  }
};

export default AwardVehicleService;
